import React from 'react';
import { DefaultSEOType, MenuType, SEOType, SettingsType } from '../types';

import Footer from './menus/Footer/Footer';
import HeaderMenu from './menus/Header/HeaderMenu';
import SEO from './seo';
import Section from './Section';
import { Breadcrumbs } from './Breadcrumbs';
import SchemaRenderer from './schemaRenderer';

interface ILayout {
  children: React.ReactNode;
  menus: MenuType;
  seo?: SEOType;
  settings: SettingsType;
  defaultSEO: DefaultSEOType;
}

function Layout({ children, seo, menus, defaultSEO, settings }: ILayout) {
  return (
    <>
      <SEO seo={seo} settingsSEO={settings.seo} defaultSEO={defaultSEO} />

      <SchemaRenderer
        schemaMarkup={seo?.schemaMarkup || []}
        pageContent={{
          title: seo?.title || defaultSEO?.title,
          description: seo?.description || defaultSEO?.description,
          _type: 'page',
        }}
        alwaysShowTypes={['WebPage']}
      />
      <HeaderMenu header={menus.header} menu={menus.menu} logins={menus.logins} />
      <main className="pt-20">{children}</main>
      <Section>
        <Breadcrumbs />
      </Section>
      <Footer footerMenu={menus.footer} socialMedia={menus.socialMedia} />
    </>
  );
}

export default Layout;
