/* eslint-disable no-case-declarations */
// components/SchemaRenderer.tsx
import React from 'react';
import Script from 'next/script';
import { breadcrumbSchemaCode, getSchemasWithDefaults } from '../utils/SchemaCodes';

interface SchemaRendererProps {
  schemaMarkup?: any[];
  pageContent: any;
  alwaysShowTypes?: string[]; // Array of types to always show
}

const SchemaRenderer: React.FC<SchemaRendererProps> = ({
  schemaMarkup = [],
  pageContent,
  alwaysShowTypes = [],
}) => {
  const schemas = getSchemasWithDefaults(schemaMarkup, pageContent);
  const coveredTypes = schemas.map((schema) => schema['@type']);

  const isArticlePage =
    pageContent?.template === 'articleFeature' || pageContent?._type === 'articleFeature';
  const isServicePage = pageContent?.template === 'service' || pageContent?._type === 'service';

  if (isArticlePage && !coveredTypes.includes('Article') && !alwaysShowTypes.includes('Article')) {
    alwaysShowTypes.push('Article');
  }

  if (isServicePage && !coveredTypes.includes('Service') && !alwaysShowTypes.includes('Service')) {
    alwaysShowTypes.push('Service');
  }

  return (
    <>
      <Script
        id="schema-script-breadcrumb"
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify(breadcrumbSchemaCode(pageContent.title, pageContent.slug)),
        }}
        strategy="afterInteractive"
      />
      {schemas.map((schema, index) => (
        <Script
          // eslint-disable-next-line react/no-array-index-key
          key={`schema-${index}`}
          id={`schema-${index}`}
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: JSON.stringify(schema) }}
          strategy="afterInteractive"
        />
      ))}
      {[...new Set(alwaysShowTypes)].map((type) => {
        if (!coveredTypes.includes(type)) {
          const defaultSchema = getDefaultSchema(type, pageContent);
          return (
            <Script
              id={`default-schema-${type}`}
              key={`default-${type}`}
              type="application/ld+json"
              dangerouslySetInnerHTML={{ __html: JSON.stringify(defaultSchema) }}
              strategy="afterInteractive"
            />
          );
        }
        return null;
      })}
    </>
  );
};

// Function to get default schema based on type
function getDefaultSchema(type: string, pageContent: any) {
  switch (type) {
    case 'faq':
      return {
        '@context': 'https://schema.org/',
        '@type': 'FAQPage',
        mainEntity: Array.isArray(pageContent?.questions)
          ? pageContent.questions.map((q: any) => ({
              '@type': 'Question',
              name: q.question,
              acceptedAnswer: {
                '@type': 'Answer',
                text: q.answer,
              },
            }))
          : [],
      };
    case 'JobPosting':
      return {
        '@context': 'https://schema.org/',
        '@type': 'JobPosting',
        title: pageContent?.jobPostingFields?.title,
        description: pageContent?.jobPostingFields?.description,
        hiringOrganization: {
          '@type': 'Organization',
          name: 'Møller Bil',
        },
        jobLocation: {
          '@type': 'Place',
          address: {
            '@type': 'PostalAddress',
            streetAddress: pageContent?.jobPostingFields?.location?.streetAddress,
            postalCode: pageContent?.jobPostingFields?.location?.postalCode,
            addressLocality: pageContent?.jobPostingFields?.location?.addressLocality,
            addressCountry: pageContent?.jobPostingFields?.location?.addressCountry,
          },
        },
        baseSalary: {
          '@type': 'MonetaryAmount',
          currency: 'NOK',
          value: {
            '@type': 'QuantitativeValue',
            value: pageContent?.jobPostingFields?.baseSalary || 'Competitive',
            unitText: 'YEAR',
          },
        },
        employmentType: pageContent?.jobPostingFields?.employmentType || 'FULL_TIME',
        datePosted: pageContent?.jobPostingFields?.datePosted,
        validThrough: pageContent?.jobPostingFields?.validThrough,
      };
    case 'LocalBusiness':
      const addresses =
        pageContent?.addressInfoList?.map(
          (addr: { address?: string; postCode?: string; postLocation?: string }) => ({
            '@type': 'PostalAddress',
            streetAddress: addr?.address || 'Default Street',
            postalCode: addr?.postCode || '0000',
            addressLocality: addr?.postLocation || 'Default Locality',
            addressCountry: 'NO',
          }),
        ) || [];

      return {
        '@context': 'https://schema.org/',
        '@type': 'LocalBusiness',
        name: pageContent?.title || 'Default Business Name',
        address: addresses.length === 1 ? addresses[0] : addresses,
        url: pageContent?.url || `https://mollerbil.no/${pageContent?.slug || 'default'}`,
      };
    case 'Article':
      return {
        '@context': 'https://schema.org/',
        '@type': 'Article',
        headline: pageContent?.title || '',
        description: pageContent?.description || pageContent?.excerpt || '',
        image: pageContent?.image?.asset?.url || pageContent?.content?.image?.asset?.url || '',
        datePublished: pageContent?.publishedAt || '',
        dateModified: pageContent?.updatedAt || pageContent?.publishedAt || '',
        author: {
          '@type': 'Organization',
          name: 'Møller Bil',
          url: 'https://mollerbil.no',
        },
        publisher: {
          '@type': 'Organization',
          name: 'Møller Bil',
          logo: {
            '@type': 'ImageObject',
            url: 'https://mollerbil.no/logo.png',
          },
        },
        mainEntityOfPage: {
          '@type': 'WebPage',
          '@id': `https://mollerbil.no/${pageContent?.slug?.current || ''}`,
        },
      };
    case 'Event':
      return {
        '@context': 'https://schema.org/',
        '@type': 'Event',
        name: pageContent?.eventName,
        url: pageContent?.eventUrl,
      };
    case 'Service':
      return {
        '@context': 'https://schema.org/',
        '@type': 'Service',
        name: pageContent?.title || '',
        description: pageContent?.description || '',
        provider: {
          '@type': 'Organization',
          name: 'Møller Bil',
          url: 'https://mollerbil.no',
        },
        areaServed: {
          '@type': 'Country',
          name: 'Norway',
        },
        serviceType: pageContent?.serviceType || '',
        offers: {
          '@type': 'Offer',
          availability: 'https://schema.org/InStock',
          price: pageContent?.price || '',
          priceCurrency: 'NOK',
        },
        url: `https://mollerbil.no/${pageContent?.slug?.current || ''}`,
        image: pageContent?.image?.asset?.url || '',
        termsOfService: pageContent?.termsOfService || '',
        category: pageContent?.category || '',
      };
    case 'WebPage':
      return {
        '@context': 'https://schema.org',
        '@type': 'WebPage',
        '@id': `https://mollerbil.no/${pageContent?.slug?.current || ''}`,
        url: `https://mollerbil.no/${pageContent?.slug?.current || ''}`,
        name: pageContent?.title || '',
        description: pageContent?.description || '',
        isPartOf: {
          '@type': 'WebSite',
          '@id': 'https://mollerbil.no/#website',
          name: 'Møller Bil',
          url: 'https://mollerbil.no',
        },
        publisher: {
          '@type': 'Organization',
          name: 'Møller Bil',
          url: 'https://mollerbil.no',
        },
        inLanguage: 'no',
      };
    case 'WebSite':
      return {
        '@context': 'https://schema.org',
        '@type': 'WebSite',
        '@id': 'https://mollerbil.no/#website',
        url: 'https://mollerbil.no',
        name: 'Møller Bil',
        description: 'Møller Bil - Din forhandler av Volkswagen, Audi, ŠKODA og SEAT',
        publisher: {
          '@type': 'Organization',
          name: 'Møller Bil',
          url: 'https://mollerbil.no',
        },
        inLanguage: 'no',
        potentialAction: [
          {
            '@type': 'SearchAction',
            target: 'https://mollerbil.no/sok?q={search_term_string}',
            'query-input': 'required name=search_term_string',
          },
        ],
      };
    case 'Organization':
      return {
        '@context': 'https://schema.org/',
        '@type': 'Organization',
        name: 'Møller Bil',
        url: 'https://mollerbil.no',
        logo: 'https://mollerbil.no/logo.png',
        address: {
          '@type': 'PostalAddress',
          streetAddress: 'Frysjaveien 31B',
          addressLocality: 'Oslo',
          postalCode: '0884',
          addressCountry: 'NO',
        },
      };
    default:
      return {};
  }
}

export default SchemaRenderer;
