import { twMerge } from 'tailwind-merge';

function LogoSymbol({ className }: { className?: string }) {
  return (
    <svg
      width="50"
      height="50"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={twMerge(`h-[50px] w-[50px]`, className)}
    >
      <title>Møller Bil</title>
      <path
        id="Vector"
        d="M28.437 0.835324V11.9096C33.9741 13.4179 37.7476 18.5318 37.7476 25.1286C37.7476 31.7253 33.9759 36.6551 28.437 38.0996V49.1757C41.1618 47.6819 50 38.0121 50 25.1286C50 12.2451 40.8991 2.39104 28.437 0.835324ZM21.563 11.8785V0.818909C9.13551 2.33815 0 12.1558 0 25.1286C0 38.1014 8.86923 47.7184 21.563 49.1811V38.1105C16.0514 36.6861 12.2524 31.7509 12.2524 25.1286C12.2524 18.5063 16.004 13.3413 21.563 11.8785Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default LogoSymbol;
